import { Block, Flexbox, Paragraph } from '@stage-ui/core'

import PageWrap from '@src/components/PageWrap'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

const S08Workflow = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox {...props}>
      <Block h="1.375rem" flex={1} backgroundColor="red800" />
      <PageWrap px={0}>
        <Flexbox alignItems="center">
          <Block h="1.375rem" backgroundColor="red800">
            <Block
              w="0.75rem"
              mt="0.3125rem"
              h="0.75rem"
              borderRadius="1rem"
              backgroundColor="white"
            >
              <Paragraph color="gray500" pt="1.75rem">
                Разработка
              </Paragraph>
            </Block>
          </Block>

          <Block h="1.375rem" flex={1} backgroundColor="red800" />

          <Block
            h="1.375rem"
            w="1rem"
            backgroundColor="red800"
            borderRadius="0 1rem 1rem 0"
          >
            <Block
              w="0.75rem"
              mt="0.3125rem"
              h="0.75rem"
              borderRadius="1rem"
              backgroundColor="white"
            >
              <Paragraph color="gray500" pt="1.75rem">
                Тестирование
              </Paragraph>
            </Block>
          </Block>

          <Block
            h="0.0625rem"
            flex={1}
            borderColor="green400"
            style={{ borderBottom: '0.0625rem solid' }}
          />
          <Block
            w="0.3125rem"
            mt="0.0625rem"
            h="0.3125rem"
            borderRadius="1rem"
            backgroundColor="black"
          >
            <Paragraph color="gray500" mt="xl">
              Внедрение
            </Paragraph>
          </Block>
          <Block
            h="0.0625rem"
            flex={1}
            borderColor="green300"
            style={{ borderBottom: '0.0625rem solid' }}
          />
          <Block
            w="0.3125rem"
            mt="0.0625rem"
            h="0.3125rem"
            borderRadius="1rem"
            backgroundColor="black"
          >
            <Paragraph color="gray500" mt="xl">
              Поддержка
            </Paragraph>
          </Block>
          <Block
            h="0.0625rem"
            flex={1}
            borderColor="green200"
            style={{ borderBottom: '0.0625rem dashed' }}
          />
        </Flexbox>
      </PageWrap>
      <Block
        h="0.0625rem"
        flex={1}
        borderColor="green200"
        style={{ borderBottom: '0.0625rem dashed' }}
      />
    </Flexbox>
  )
}

export default S08Workflow
